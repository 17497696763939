import React from "react";

const LATEST_VERSION = "10.9";

const CurrentFilter = props => {
    let version = props.selectedVersion
    let type = props.selectedType

    function renderFilterComma() { if (type != "") { return (", "); } }

    if (version == "" && type == "") {
        return (
            <div />
        );
    }

    function isLatest(version) {
        if (version === LATEST_VERSION) {
            return "aktuelle Version";
        }
        return version;
    }

    return (

        <div className="currentFilterContainer">
            <span>
                Filter: {isLatest(version)}{renderFilterComma()}{type}
            </span>
            <button
                id="ResetFilter"
                name="ResetFilterButton"
                className="buttonResetFilter"
                onClick={() => props.onResetFilterCallback()}
            >x</button>
        </div>

    );
};

export default CurrentFilter;
