import React from "react";

const Header = props => {
	return (
		<div className="headerDiv">
			<img src="logo.png" />
			<h2 className="headerTitle">GeoOffice Installationszentrale</h2>
			<p className="headerText">
				{props.displayText}
			</p>
		</div>
	);
};

export default Header;
