import React from "react";

const SetupsTable = ({ setups }) => {
	function compareSetups(left, right) {

		// sort by version name descendending
		if (left.version > right.version)
			return -1;
		if (left.version < right.version)
			return 1;

		let leftSortName = left.displayName.toUpperCase().replace(/^GeoOffice[ ]*/, "");
		let rightSortName = right.displayName.toUpperCase().replace(/^GeoOffice[ ]*/, "");

		// sort by display name ascendending
		if (leftSortName > rightSortName)
			return 1;
		if (leftSortName < rightSortName)
			return -1;

		return 0;
	}
	const isProduction = window.location.hostname === "resources.geooffice.vertigis.com";
	console.log("isProduction: " + isProduction);
	setups.sort(compareSetups);
	function selectAll() {
		let headerCheckBox = document.getElementById('headerCheckBox');
		let checkboxes = document.querySelectorAll('input[type="checkbox"]');
		checkboxes.forEach(checkbox => {
			checkbox.checked = headerCheckBox.checked;
		});
	}

	return (
		<table className="setupsTable" id="sTable">
			<thead className="tableHead">
				<tr className="headRow" id="hRow">
					{!isProduction && <th className="selectionCell"><input type="checkbox" id="headerCheckBox" onClick={() => selectAll()}></input></th>}
					<th key="name" className="nameCell">Name</th>
					<th className="versionCell">Version</th>
					<th className="dateCell">Datum</th>
					<th className="typeCell">Typ</th>
					<th className="buttonCell">Download</th>
					<th className="manualCell">Handbuch</th>
				</tr>
			</thead>
			<tbody className="tableBody">
				{setups.map((s, index) => (
					<tr
						key={s.version + " " + s.fileName + index}
						className={"bodyRow" + (index % 2 !== 0 ? "-odd" : "-even")}
						data-filtered="none">
						{!isProduction && <td className="selectionCell"><input type="checkbox" value={s.downloadLink}></input></td>}
						<td className="nameCell">
							{s.fileversion && !s.version.startsWith("10.8") ? <a href={s.changeLogUrl} target="_blank">{s.displayName === '' ? s.fileName : s.displayName}</a> : s.displayName === '' ? s.fileName : s.displayName}
						</td>
						<td className="versionCell">{s.fileversion}</td>
						<td className="dateCell">{s.date}</td>
						<td className="typeCell">{s.type}</td>
						<td className="buttonCell">
							<a href={s.downloadLink} download>{s.downloadDisplayName}</a>
						</td>
						<td className="manualCell">
							<a href={s.manual} target="_blank">{(s.manual !== "" && s.manual !== null) ? "Handbuch" : ""}</a>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};
export default SetupsTable;
