import React from "react";

const Footer = () => {
	return (
		<div className="footerDiv">
			<div className="footerVersion"><span>20240326.4</span></div>
			<div className="footerHalfWrap">
				<h2 className="footerTitle">Kontakt</h2>

				<div className="footerTextGISFM footerHalf">
					<p className="footerCompany"> VertiGIS GmbH</p>
					<p className="footerAddress"> Wilhelm-Greil-Stra&szlig;e 17 A-6020 Innsbruck</p>
					<p className="footerTelephone">+43 (0)59908-0</p>
					<p className="footerLink"><a href="http://www.vertigis.com/">www.vertigis.com</a></p>
					<p className="footerLink"><a href="mailto:info-at@vertigis.com">info-at@vertigis.com</a></p>
					<p className="footerLink"><a href="https://www.linkedin.com/company/34895771/">LinkedIn</a> <a href="https://twitter.com/Vertigis_Europe">Twitter</a></p>
				</div>
				<div className="clearboth"></div>
			</div>
		</div>
	);
};

export default Footer;

