import React from "react";

const WhatsNewFiles = ({ whatsNew }) => {
	function compareWhatsNew(left, right) {

		// sort by version name descendending
		if (left.version > right.version)
			return -1;
		if (left.version < right.version)
			return 1;

		let leftSortName = left.displayName.toUpperCase().replace(/^GeoOffice[ ]*/, "");
		let rightSortName = right.displayName.toUpperCase().replace(/^GeoOffice[ ]*/, "");

		// sort by display name ascendending
		if (leftSortName > rightSortName)
			return 1;
		if (leftSortName < rightSortName)
			return -1;

		return 0;
	}

	whatsNew.sort(compareWhatsNew);

	return (
		<div>
			Was ist neu:
			{whatsNew.map((w, index) => (
				<a key={w.displayName} href={w.downloadLink} target="_blank">{w.displayName}</a>
				))}
		</div>
	);
};
export default WhatsNewFiles;
