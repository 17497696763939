import React, { useState, useEffect } from "react";
import Header from "./Header";
import Filter from "./Filter";
import CurrentFilter from "./CurrentFilter"
import SetupsTable from "./SetupsTable";
import WhatsNewFiles from "./WhatsNewFiles";
import SearchBar from "./SearchBar";
import Footer from "./Footer";

const Main = () => {
	const [setups, setSetups] = useState([]);
	const [whatsNew, setWhatsNew] = useState([]);
	const [loading, setLoading] = useState(true);
	const [whatsNewLoading, setWhatsNewLoading] = useState(true);
	const [originalSetups, setOriginalSetups] = useState([]);
	const [filter, setFilter] = useState({ version: "", type: "", name: "" });

	useEffect(() => {
		async function fetchSetupsFromApi() {
			let response = await fetch("./api/ProvidedData/GetSetupsInFolders");
			let json = await response.json();

			setSetups(json);
			setOriginalSetups(json);
			setLoading(false);
			setFilter({ version: "", type: "", name: "" });
		}
		async function fetchWhatsNewFromApi() {
			let response = await fetch("./api/ProvidedData/GetWhatsNew");
			let json = await response.json();
			console.log(json);
			setWhatsNew(json);
			setWhatsNewLoading(false);
		}
		fetchSetupsFromApi().then(() => { });
		fetchWhatsNewFromApi().then(() => { });
	}, []);

	const filterSetupsTable = () => {
		let filteredSetups = originalSetups;

		if (filter.version !== "") {
			filteredSetups = filteredSetups.filter(s => {
				return (
					s.version === filter.version || s.version === "all"
				);
			});
		}
		if (filter.type !== "") {
			filteredSetups = filteredSetups.filter(s => s.type === filter.type || s.version === 'all');
		}
		if (filter.name !== "") {
			filteredSetups = filteredSetups.filter(s =>
				s.displayName.toUpperCase().includes(filter.name.toUpperCase())
			);
		}
		setSetups(filteredSetups);
	};

	const resetTypeFilter = () => {
		let radioButtons = document.getElementsByClassName("typeRadioButton");
		radioButtons = Array.from(radioButtons);
		radioButtons.forEach(r => {
			r.checked = false;
		});
	};

	const onUpdateFilter = (filterType, data) => {
		let newFilter = filter;

		switch (filterType) {
			case "version":
				{
					resetTypeFilter();
					newFilter.type = "";
					newFilter.version = data;
				}
				break;
			case "name":
				newFilter.name = data;
				break;
			case "type": {
				newFilter.version = data.version;
				newFilter.type = data.type;
			}
				break;
			default:
				break;
		}
		setFilter(newFilter);
		filterSetupsTable();
	};

	const resetFilter = () => {
		let newFilter = filter;
		newFilter.version = "";
		newFilter.type = "";
		setFilter(newFilter);
		filterSetupsTable();
	}

	const onReleaseProducts = () => {
		var array = []
		var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

		for (var i = 0; i < checkboxes.length; i++) {
			array.push(checkboxes[i].value)
		}
		fetch("/api/ProvidedData/ReleaseProducts", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(array),
		})
	}

	const newestReleaseText = () => {

		let release = filter.version;
		if (release === "") {
			return "alle Releases";
		}
		if (release === "10.9") {
			release = "aktuelle Version";
		}
		return "ausgewählt: " + release;
	};

	let filterSection = loading ? (
		<div className="loading-filter" />
	) : (
		<>
			<div className={"buttonFilterContainer"}>
				<Filter
					originalSetups={originalSetups}
					onUpdateFilterCallback={onUpdateFilter}
					onReleaseProductsCallback={onReleaseProducts}
				/>
			</div>
			<CurrentFilter selectedVersion={filter.version} selectedType={filter.type} onResetFilterCallback={resetFilter} />
			<SearchBar filter={filter} onUpdateFilterCallback={onUpdateFilter} />
		</>
	);
	let setupsTable = loading ? (
		<div className="loading-setups" />
	) : (
		<SetupsTable setups={setups} />
	);
	let whatsNewFiles = whatsNewLoading ? (
		<div className="loading-whastNew" />
	) : (
		<WhatsNewFiles whatsNew={whatsNew} />
	);
	return (
		<>
			<div className="newsDiv">{whatsNewFiles}</div>
			<div className="contentDiv">
				<Header displayText={newestReleaseText()} />
				<div className="filterContainer">{filterSection}</div>
				<div className="tableContainer">{setupsTable}</div>
			</div>
			<Footer />
		</>
	);
};

export default Main;
