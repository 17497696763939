import React from "react";
import TypeFilterContainer from "./TypeFilterContainer";

const LATEST_VERSION = "10.9";

const Filter = props => {
	let allSetups = props.originalSetups;
	const isProduction = window.location.hostname === "resources.geooffice.vertigis.com";
	let setupsToDisplay = props.originalSetups.filter(
		s => s.displayInFilter === true
	);
	let versions = setupsToDisplay.map(s => s.version);
	let uniqueVersionsSet = new Set(versions);
	let uniqueVersions = Array.from(uniqueVersionsSet).filter(v => v !== 'all');
	uniqueVersions.sort((a, b) => {
		if (a === LATEST_VERSION) return -1;
		if (b === LATEST_VERSION) return 1;
		return a > b ? -1 : +1;
	});
	function renderTypeFilter(allSetups, version, versionIndex) {
		let typeSetups, allTypes, uniqueTypesSet, uniqueTypes;
		typeSetups = allSetups.filter(s =>
			version.startsWith(s.version.substring(0, 4))
		);
		allTypes = typeSetups.map(ts => ts.type);
		uniqueTypesSet = new Set(allTypes);
		uniqueTypes = Array.from(uniqueTypesSet);
		uniqueTypes.sort();

		return (
			<div className="typefilterContainerDiv">
				{uniqueTypes.map((ut, index) => (
					<li key={ut + "li" + index}>
						<TypeFilterContainer
							version={version}
							versionIndex={versionIndex}
							callback={props.onUpdateFilterCallback}
							id={index}
							type={ut}
							key={index}
						/>
					</li>
				))}
			</div>
		);
	}

	function isLatest(version) {
		if (version === LATEST_VERSION) {
			return "aktuelle Version";
		}
		return version;
	}

	return (
		<div id="wrapper">
			<div id="navMenu">
				<ul>
					{!isProduction && <input type="button" className="ReleaseButton" onClick={() => props.onReleaseProductsCallback()} />}
					<p className="FilterListText">Releases: </p>
					{uniqueVersions.map((v, index) => (
						<li key={v + "li" + index}>
							<div key={v + "div" + index} className="versionFilterContainerDiv">
								<div className="versionFilterWrap">
									<label
										className="versionFilterLabel"
										name={v}
										id={v}
										key={v + " " + index}
									>
										<input
											type="radio"
											id={v + index}
											key={v + "radio" + index}
											name="groupMain"
											className="versionRadioButton"
											onClick={() => props.onUpdateFilterCallback("version", v)}
										/>
										<span key={v + "span" + index}>{isLatest(v)}</span>
									</label>
								</div>
								<ul>
									<div className="radio-group-sub">
										{renderTypeFilter(allSetups, v, index)}
									</div>
								</ul>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default Filter;
