import React from "react";

const SearchBar = props => {
  const getChangedValue = () => {
      return document.getElementById("filterNameInput").value;
  };

  return (
      <div className="searchContainer">
          <button
              id="ResetSearchBar"
              name="ResetSearchBar"
              className="ResetSearchBarButton"
              onClick={() => props.onUpdateFilterCallback("name", "")}
          >x</button>  
      <input
        type="text"
        className="searchBar"
        id="filterNameInput"
        value={props.filter.name === undefined ? "" : props.filter.name}
        onChange={() => props.onUpdateFilterCallback("name", getChangedValue())}
        placeholder="Nach Setups suchen..."
          />
       
    </div>
  );
};

export default SearchBar;
