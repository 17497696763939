import React from 'react';

const TypeFilterContainer = ({ version, versionIndex, type, id, callback }) => {
    return (
        <div className={"typeRadioButtonDiv " + "typeRb"+version}>
            <input
                id={version + versionIndex + id + "radio"}
                type="radio"
                name="group2"
                className={"typeRadioButton"}
                onClick={() => callback("type", {type: type, version: version})}
            />
            <label htmlFor={version + versionIndex + id + "radio"} className={"label"+version}>{type}</label>
        </div>
    );
};
export default TypeFilterContainer